var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.is_loading,
                  expression: "!is_loading",
                },
              ],
              staticClass:
                "text-center justify-center q-pa-md q-gutter-sm fixed-center",
              staticStyle: { "z-index": "1000" },
            },
            [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
            1
          ),
          _c("div", { staticClass: "row justify-center" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-5", attrs: { align: "left" } },
              [
                _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
                  _vm._v("(1) - Carica i documenti richiesti:"),
                ]),
                _c("br"),
                _c(
                  "ul",
                  _vm._l(_vm.elenco_documenti, function (documento, index) {
                    return _c("li", { key: index }, [
                      _vm._v(
                        " " + _vm._s(documento.descrizione_documento) + " "
                      ),
                    ])
                  }),
                  0
                ),
                _c("br"),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-5", attrs: { align: "center" } },
              [
                _c("q-uploader", {
                  ref: "UploadObject",
                  staticStyle: {
                    "max-width": "800px",
                    width: "100%",
                    "min-height": "300px",
                    "box-shadow":
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  },
                  attrs: {
                    url: "https://localhost:1000",
                    color: "teal",
                    label:
                      "Trascina QUI i documenti da inviare per emettere i contratti",
                    multiple: "",
                    "hide-upload-btn": "",
                    name: "upload_documenti",
                    filter: _vm.checkFileType,
                  },
                  on: {
                    rejected: _vm.onRejected,
                    added: _vm.onAggiungiFileAllegati,
                    removed: _vm.onRimuoviFileAllegati,
                    uploading: _vm.onUploading,
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
              [
                _c("h6", { staticClass: "q-mb-sm q-mt-sm text-center" }, [
                  _vm._v("Carica documento firmato manualmente:"),
                ]),
                _c("br"),
                _c("br"),
                _c("q-uploader", {
                  ref: "UploadDichiarazioniCliente",
                  staticStyle: {
                    "max-width": "800px",
                    width: "100%",
                    "min-height": "300px",
                    "box-shadow":
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  },
                  attrs: {
                    url: "https://localhost:1000",
                    color: "teal",
                    label:
                      "Trascina QUI il PDF della dichiarazione del Contraente FIRMATA",
                    multiple: "",
                    "hide-upload-btn": "",
                    name: "upload_dichiarazioni_contraente",
                    filter: _vm.checkFileType,
                  },
                  on: { rejected: _vm.onRejected, uploading: _vm.onUploading },
                }),
              ],
              1
            ),
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "col-12 col-md-4 justify-center",
                attrs: { align: "left" },
              },
              [
                _vm.otp_identificato
                  ? _c("div", [
                      _c("h6", { staticClass: "q-mb-sm q-mt-sm text-center" }, [
                        _vm._v(
                          "Firma dichiarazioni del Contraente effettuata con successo"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isOtpFirmaDocumentiEnabled && !_vm.otp_identificato
                  ? _c("div", [
                      _c("h6", { staticClass: "q-mb-sm q-mt-sm text-center" }, [
                        _vm._v("Firma dichiarazioni del Contraente con OTP"),
                      ]),
                      !_vm.info_cellulare.is_certificato
                        ? _c("div", [
                            _c("strong", [_vm._v("ATTENZIONE:")]),
                            _c("br"),
                            _c("br"),
                            _vm._v(" Firma via OTP non disponibile."),
                            _c("br"),
                            _c("strong", [_vm._v("MOTIVO: ")]),
                            _vm._v(
                              "Il numero di cellulare non risulta essere certificato per attivare la firma OTP. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " Per certificare un cellulare torna alla pagina precedente. "
                            ),
                          ])
                        : _vm._e(),
                      _vm.info_cellulare.is_bloccato
                        ? _c("div", [
                            _c("strong", [_vm._v("ATTENZIONE:")]),
                            _c("br"),
                            _c("br"),
                            _vm._v(" Firma via OTP non disponibile."),
                            _c("br"),
                            _c("strong", [_vm._v("MOTIVO: ")]),
                            _vm._v(
                              "Il numero di cellulare risulta essere bloccato e non può essere utilizzato per la firma dei documenti. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " Contatta la Sede per stabilire come procedere. "
                            ),
                          ])
                        : _vm._e(),
                      !_vm.info_cellulare.is_bloccato &&
                      _vm.info_cellulare.is_certificato
                        ? _c(
                            "div",
                            { attrs: { align: "center" } },
                            [
                              _c("br"),
                              _c("br"),
                              _c("QQButton", {
                                attrs: {
                                  label: "Effettua la firma OTP",
                                  color: "blue-grey",
                                  icon: "mdi-email-check-outline",
                                  size: "md",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onVisualizzaModaleOtp.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _c(
                                "q-dialog",
                                {
                                  attrs: { persistent: "" },
                                  model: {
                                    value: _vm.visualizza_finestra_otp,
                                    callback: function ($$v) {
                                      _vm.visualizza_finestra_otp = $$v
                                    },
                                    expression: "visualizza_finestra_otp",
                                  },
                                },
                                [
                                  _c(
                                    "q-card",
                                    { staticStyle: { width: "600px" } },
                                    [
                                      _c("q-card-section", [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v(
                                            "Firma della dichiarazione del Contraente con OTP"
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "q-card-section",
                                        { staticClass: "q-pt-none" },
                                        [
                                          _c("QQOtp", {
                                            attrs: {
                                              title: "",
                                              cellulare: _vm.cellulare_cliente,
                                              guid_cliente:
                                                _vm.formPreventivo.IDCliente,
                                            },
                                            model: {
                                              value: _vm.otp_identificato,
                                              callback: function ($$v) {
                                                _vm.otp_identificato = $$v
                                              },
                                              expression: "otp_identificato",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-card-actions",
                                        { attrs: { align: "right" } },
                                        [
                                          _c("q-btn", {
                                            directives: [
                                              {
                                                name: "close-popup",
                                                rawName: "v-close-popup",
                                              },
                                            ],
                                            attrs: {
                                              label: "ESCI",
                                              color: "secondary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                !_vm.isOtpFirmaDocumentiEnabled
                  ? _c("div", [
                      _c("br"),
                      _c("strong", [_vm._v("ATTENZIONE")]),
                      _c("br"),
                      _vm._v(
                        " La firma dei documenti con procedura OTP non è abilitata. "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c("br"),
          _c("hr"),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "INDIETRO",
                    color: "blue-grey",
                    icon: "undo",
                    size: "md",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIndietroClicked.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
              [
                _c("QQButton", {
                  attrs: {
                    label: "AVANTI",
                    color: "blue-grey",
                    icon: "mdi-arrow-right-bold",
                    size: "md",
                    disabled: !_vm.pulsante_abilitato,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onUploading.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c(
            "q-dialog",
            {
              attrs: {
                persistent: "",
                "transition-show": "scale",
                "transition-hide": "scale",
              },
              model: {
                value: _vm.show_dialog,
                callback: function ($$v) {
                  _vm.show_dialog = $$v
                },
                expression: "show_dialog",
              },
            },
            [
              _c(
                "q-card",
                { staticStyle: { width: "500px" } },
                [
                  _c("q-card-section", { staticClass: "bg-teal text-white" }, [
                    _c("div", [_vm._v("TIPO DI DOCUMENTO ALLEGATO:")]),
                  ]),
                  _c(
                    "q-card-section",
                    { staticClass: "q-pt-none" },
                    [
                      _c("br"),
                      _vm._l(
                        _vm.getElencoDocumentiDaSelezionare,
                        function (documento, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("q-checkbox", {
                                attrs: {
                                  label: documento.descrizione_documento,
                                  type: "checkbox",
                                },
                                model: {
                                  value: documento.is_checked,
                                  callback: function ($$v) {
                                    _vm.$set(documento, "is_checked", $$v)
                                  },
                                  expression: "documento.is_checked",
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "q-card-actions",
                    {
                      staticClass: "bg-white text-teal",
                      attrs: { align: "right" },
                    },
                    [
                      _c("q-btn", {
                        directives: [
                          { name: "close-popup", rawName: "v-close-popup" },
                        ],
                        attrs: { label: "OK", color: "primary" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 col-md-12", attrs: { align: "center" } },
      [
        _c("br"),
        _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
          _vm._v("(2) - Firma Dichiarazioni del Contraente:"),
        ]),
        _c("br"),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-12 col-md-2 justify-center",
        attrs: { align: "center" },
      },
      [_c("br"), _c("br"), _c("br"), _c("h5", [_vm._v("OPPURE")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }